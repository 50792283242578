import React, { useState } from 'react'
import AuthorizationCodesList from 'features/AuthorizationCodesList'
import SearchInput from 'features/SearchInput'
import { Box } from '@mui/material'

const App = () => {
  const [searchQuery, setSearchQuery] = useState('')

  const filterSentAuthorizationCodes = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value)
  }

  return (
    <Box
      sx={{
        margin: 'auto',
        maxWidth: '644px',
        height: '98vh',
      }}
    >
      <Box sx={{ marginBottom: '40px' }}>
        <SearchInput
          setSearchQuery={filterSentAuthorizationCodes}
          searchQuery={searchQuery}
        />
      </Box>

      <AuthorizationCodesList searchQuery={searchQuery} />
    </Box>
  )
}

export default App
