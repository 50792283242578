import React from 'react'
import Search from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'types'

interface setSearchQuery {
  setSearchQuery: (e: React.ChangeEvent<HTMLInputElement>) => void
  searchQuery?: string
}

const SearchInput = ({ setSearchQuery, searchQuery }: setSearchQuery) => {
  const {
    verifieringskoder: { searchPlaceholder },
  } = useEpiContent<IContent>()

  return (
    <Box sx={{ width: '100%', marginTop: '20px' }}>
      <OutlinedInput
        inputProps={{ 'data-testid': 'search-authorization-codes-input' }}
        fullWidth
        placeholder={searchPlaceholder}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setSearchQuery(event)
        }}
        value={searchQuery}
        endAdornment={
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        }
      />
    </Box>
  )
}

export default SearchInput
